/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600&display=swap');
html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif!important;
}

.mat-drawer-container {
    background-color: black!important;
}

.table {
    color: white!important;
}

.head-table {
    background: #fa8a04;
    position: sticky;
    top: -24px;
    border: none;
    z-index: 999;
}

.head-table-item {
    border: none;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #383b3e!important;
}

label {
    color: white !important;
}


/* Importing Bootstrap SCSS file. */

@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */

@import "~ngx-bootstrap/datepicker/bs-datepicker";
.naviagation {
    width: 98%;
    margin: auto;
    position: relative;
    height: 50px;
    background-color: #100d14;
    padding: 13px;
    color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
}

.btn-nav {
    width: 100px;
    height: 25px;
    color: white;
    background-color: #fa8a04;
    padding: 8px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    justify-content: center;
    display: flex;
    align-items: center;
}

.btn-nav:disabled {
    background-color: #ccc;
}

.nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "~ngx-bootstrap/datepicker/bs-datepicker";
